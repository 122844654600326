import { Component, DoCheck, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/core/services/home.service';
import { ConfigAppProvider } from 'src/app/providers/configApp.provider';

@Component({
  selector: 'app-pilar-inside',
  templateUrl: './pilar-inside.component.html',
  styleUrls: ['./pilar-inside.component.scss']
})
export class PilarInsideComponent implements OnInit {
  pilar: any;
  pilarOpen = false;
  language!: any;
  private configAppDiffer: KeyValueDiffer<string, any>;
  hiddenMenuFooter = true;
  activeAbsolute = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private homeService: HomeService,
              private differs: KeyValueDiffers, private configApp: ConfigAppProvider,
     ) {

    this.configAppDiffer = this.differs.find(this.configApp).create();

    this.activatedRoute.queryParamMap.subscribe((queryParams: any) => {
      this.detectLaguaje(queryParams);
    });
     }

  ngOnInit(): void {
    this.getRoterGuest();
    setTimeout(() => {
      this.activeAbsolute = true;
    }, 1000);
    this.getPilarInside();
    window.scrollTo({
      top: 1,
      left: 1,
      behavior: 'smooth'
    });
  }

  ngDoCheck(): void {
    const changes = this.configAppDiffer.diff(this.configApp);
    if (changes) {
      this.activatedRoute.queryParamMap.subscribe((queryParams: any) => {
        this.detectLaguaje(queryParams);
        this.getPilarInside();
      });
    }
  }

  getPilarInside(): void{
    if (this.language === 'pt') {
      this.language = 'pt_br';
    }
    this.activatedRoute.params.subscribe(argId => {
      this.homeService.getPilar(`
        {
            operator: EQUAL
            field: "field_titulo_ingles"
            value: "${argId.id}"
        }
        `
        , 100, 0,
          (pilares: any, total: any) => {
            this.pilar = pilares[0];
            this.pilarOpen = true;
          }, this.language.toUpperCase() || '{}'
        );
    });
  }

  // tslint:disable-next-line:typedef
  detectLaguaje(queryParams: any): void {
    let langStorage: any = 'en';
    try {
      langStorage = (window.localStorage) ? window.localStorage.getItem('lang') : 'en';
    } catch (error) {
    }
    let currentLanguage: any = window.navigator.language.substr(0, 2);
    if (langStorage || currentLanguage) {
      const langVP = (langStorage !== undefined && langStorage !== null) ?
        langStorage
        : currentLanguage;
      currentLanguage = (langVP === 'pt') ? 'pt' : langVP?.toLocaleLowerCase();
      if (currentLanguage.indexOf('en') !== -1 || currentLanguage.indexOf('es') !== -1 ||
        (currentLanguage.indexOf('pt') !== -1 || currentLanguage.indexOf('pr') !== -1 || langStorage === 'pr')) {
        if (currentLanguage.indexOf('en') !== -1) {
          this.language = 'en';
        } else if (currentLanguage.indexOf('pt') !== -1 || currentLanguage.indexOf('pr') !== -1 || langStorage === 'pr') {
          this.language = 'pt';
        } else if (currentLanguage.indexOf('es') !== -1) {
          this.language = 'es';
        }
      }
      if (queryParams.params !== undefined &&
        queryParams.params.language !== undefined && queryParams.params.language !== null &&
        queryParams.params.language !== 'null') {
        const langV = queryParams.params.language.toLowerCase();
        this.language = (langV === 'pt') ? 'pt' : langV;
        try {
          if (window.localStorage) {
            window.localStorage.setItem('lang', this.language);
          }
        } catch (error) {
        }
      }
      try {
        if (window.localStorage) {
          window.localStorage.setItem('lang', this.language);
        }
      } catch (error) {
      }
      // }
    } else {
      this.language = 'en';
    }
  }

  getRoterGuest(): void {
    try {
      if (window.localStorage) {
        window.localStorage.removeItem('guest');
      }
    } catch (error) { }
    this.router.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('guest')) {
          const urlString = event.url;
          const ulr = new URL('https://tester' + urlString);
          const guest = ulr.searchParams.get('guest');
          if (guest === 'false') {
            try {
              if (window.localStorage) {
                window.localStorage.setItem('guest', 'false');
              }
            } catch (error) { }
            this.hiddenMenuFooter = false;
          } else {
            try {
              if (window.localStorage) {
                window.localStorage.setItem('guest', 'true');
              }
            } catch (error) { }
            this.hiddenMenuFooter = true;
          }
        } else {
          this.menuChangeIfLocalStorageIsActive();
        }
      } else {
        this.menuChangeIfLocalStorageIsActive();
      }
    });
  }

  private menuChangeIfLocalStorageIsActive(): void {
    if (
      window.localStorage &&
      window.localStorage.getItem('guest') !== 'undefined' &&
      window.localStorage.getItem('guest') !== null &&
      window.localStorage.getItem('guest') !== 'null'
    ) {
      let guest: any = false;
      try {
        if (window.localStorage) {
          guest = window.localStorage.getItem('guest');
        }
      } catch (error) {
      }
      this.hiddenMenuFooter = (guest === 'true');
    } else {
      this.hiddenMenuFooter = true;
    }
  }

}
