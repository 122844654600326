import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/operators';

declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'connectamericas';
  wowSubscription!: Subscription;

  constructor(
    private router: Router,
    private wowService: NgwWowService
  ) {
    try {
      if (window.localStorage) {
        const langV: any = localStorage.getItem('lang');
        window.localStorage.setItem('lang', (langV !== null &&
         langV !== 'null') ?
         langV : 'en');
      }
    } catch (error) {
    }
    const navEndEvents$ = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    );
    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-VZ2YYT09XK', {
        page_path: event.urlAfterRedirects
      });
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      // Reload WoW animations when done navigating to page,
      // but you are free to call it whenever/wherever you like
      this.wowService.init();
    });
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    // you can subscribe to WOW observable to react when an element is revealed
    this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item: HTMLElement) => {
        // do whatever you want with revealed element
    });
  }
  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.wowSubscription.unsubscribe();
  }
}
