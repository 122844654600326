import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './mudules/home/home.component';
import { PilarInsideComponent } from './mudules/pilar-inside/pilar-inside.component';

const routerOptions: ExtraOptions = {
  useHash: true,
  // scrollOffset: [0, 64],
};

const APP_ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'women/:id', component: PilarInsideComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, routerOptions) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
