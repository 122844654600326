import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  public sendContactConoce(form: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/vnd.api+json',
        'Authorization': 'Basic ' + btoa('admin:S1n@ps1s')
      })
    };
    return this.http.post('https://live-mujeres-connectamerica.pantheonsite.io/jsonapi/node/conoce_mas', form, httpOptions);
  }

  public sendContactUnete(form: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/vnd.api+json',
        'Authorization': 'Basic ' + btoa('admin:S1n@ps1s')
      })
    };
    return this.http.post('https://live-mujeres-connectamerica.pantheonsite.io/jsonapi/node/unete_ahora', form, httpOptions);
  }

  public sendContactInformacion(form: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/vnd.api+json',
        'Authorization': 'Basic ' + btoa('admin:S1n@ps1s')
      })
    };
    return this.http.post('https://live-mujeres-connectamerica.pantheonsite.io/jsonapi/node/solicita_informacion', form, httpOptions);
  }
}
