import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigAppProvider } from 'src/app/providers/configApp.provider';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  defaultSpanish = false;
  defaultEnglis = false;
  defaultPortuguese = false;
  activeLang = 'en';
  environmentProd!: string;
  activeAbsolute = false;


  constructor(
    private router: Router,
    private translate: TranslateService,
    private configApp: ConfigAppProvider) {
    let langStorage: any = 'en';
    try {
      langStorage = (window.localStorage) ? window.localStorage.getItem('lang') : 'en';
    } catch (error) {}
    if (langStorage) {
      this.useLanguage(langStorage || '{}');
    } else {
      const currentLanguage = window.navigator.language;
      if (currentLanguage.indexOf('en') !== -1 || currentLanguage.indexOf('es') !== -1 || currentLanguage.indexOf('pt_br') !== -1) {
        if (currentLanguage.indexOf('en') !== -1) {
          this.defaultSpanish = false;
          this.activeLang = 'en';
        } else if (currentLanguage.indexOf('es') !== -1) {
          this.defaultSpanish = true;
          this.activeLang = 'es';
        } else if (currentLanguage.indexOf('pt') !== -1) {
          this.defaultSpanish = true;
          this.activeLang = 'es';
        }
      }
      try {
        if (window.localStorage) {
          window.localStorage.setItem('lang', this.activeLang);
        }
      } catch (error) {}
      translate.setDefaultLang(this.activeLang);
    }
  }

  ngOnInit(): void {
    this.environmentProd = environment.urlProd;
    setTimeout(() => {
      this.activeAbsolute = true;
    }, 1000);

  }

  useLanguage(lang: string): void {
    this.configApp.language = lang.toUpperCase();
    if (lang === 'es') {
      this.defaultSpanish = false;
      this.defaultEnglis = true;
      this.defaultPortuguese = true;
    } else if (lang === 'en') {
      this.defaultSpanish = true;
      this.defaultEnglis = false;
      this.defaultPortuguese = true;
    } else if (lang === 'pt') {
      this.defaultSpanish = true;
      this.defaultEnglis = true;
      this.defaultPortuguese = false;
    }
    this.activeLang = lang;
    try {
      if (window.localStorage) {
        window.localStorage.setItem('lang', lang);
      }
    } catch (error) {}
    this.translate.use(lang);
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  // tslint:disable-next-line:typedef
  contactClick(){
    window.parent.postMessage('openContact', this.environmentProd);
  }

}
