<div class="content-home w-100">
  <app-nav-menu *ngIf="hiddenMenuFooter"></app-nav-menu>
  <div id="content" class="contenbanner" #main [ngClass]="{'pt-0': !hiddenMenuFooter}">
    <div class="banner">
      <div class="img-banner">
        <div class="breadcrumb-int">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="https://connectamericas.com/" target="_parent">
                {{ 'home' | translate}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ 'connectamericaswomen' | translate}} </li>
          </ol>
        </div>
        <div class="sec-title">
          <h2>
            <img class="img-fluid" src="{{ 'imgmujereslogo' | translate}}" alt="">
          </h2>
          <p class="wow animated fadeIn" data-wow-duration="5s" data-wow-delay="0.7s">
            {{'titlebanner' | translate}}
          </p>
        </div>
      </div>
    </div>

    <div class="content-card-home">
      <div class="circle1" [ngClass]="{'position-absolute': activeAbsolute}"></div>
      <div class="circle2" [ngClass]="{'position-absolute': activeAbsolute}"></div>
      <div class="card-program">
        <h3>{{ 'teinvitamosconocer' | translate}} </h3>
        <h2>{{ 'titulocreciendojuntasamerica' | translate}}</h2>
        <p>
          <span>
            {{ 'textocreciendojuntasamerica' | translate}}
          </span>
        </p>
        <div class="input-group sec-suscribe">
          <form class="formEmail" [formGroup]="userOneEmails">
            <div class="form-group">
              <input type="email" [class.is-invalid]="primEmail.primaryEmail.invalid && primEmail.primaryEmail.touched"
                formControlName="primaryEmail" class="form-control" placeholder="{{'paceholderemailcorpo'| translate}}"
                aria-label="Ingresa tu email corporativo" aria-describedby="button-addon2">
            </div>
          </form>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="sendEmail(1)">
              <span *ngIf="!isMobile || isTablet">{{'btnconocemas'| translate}}</span>
              <span *ngIf="isMobile  || isTablet">{{'btnconocemas'| translate}}</span>
            </button>
          </div>
        </div>
        <div class="alert-message"
          *ngIf="(primEmail.primaryEmail.invalid && primEmail.primaryEmail.touched) || primEmail.primaryEmail.dirty">
          <small *ngIf="primEmail.primaryEmail.errors?.required" class="text-danger">{{ 'alertcalltoaction' | translate
            }}</small>
          <!--question mark(?) is a safe navigation operator-->
          <small *ngIf="primEmail.primaryEmail.errors?.pattern" class="text-danger">{{ 'alertcalltoaction2' | translate
            }}</small>
        </div>
        <p class="pemail" *ngIf="(primEmail.primaryEmail.valid) && sendEmailValid && sendEmailAlliances">
          {{'infoenviomensaje'| translate}}
        </p>
        <div class="row mt-4 pt-5 mb-5 pb-5">
          <div class="col-12 col-md-4 d-flex flex-wrap align-content-center" *ngFor="let pilar of pilar">
            <div class="card-pilar"
              [ngClass]="{'grayclass': pilar.fieldOrdenPilar !== 2}">
              <div class="headband-nov" *ngIf="pilar.fieldNovedad">
                <span>{{'novedades'| translate}}</span>
              </div>
              <img class="img-fluid img-pilar" [src]="pilar.fieldIcono.url" alt="">
              <h3 class="title-pilar">{{ pilar.title }}</h3>
              <p>{{ pilar.fieldDescripcionPilar.processed }}</p>
              <div class="xbtn-absolute">
                <ng-container *ngIf="pilar.fieldCerrada === false && pilar.fieldTextoInterno">
                  <button class="btn btn-participate" type="button" [routerLink]="['/women', pilar.fieldTituloIngles]">
                    <span>
                      {{'postulate'| translate}}
                    </span>
                  </button>
                </ng-container>
                <ng-container *ngIf="pilar.fieldCerrada === true">
                  <button class="btn btn-closed" type="button" >
                    <span class="sp-closed">
                      {{'cerrada'| translate}}
                    </span>
                  </button>
                </ng-container>
                <button class="btn btn-closed" type="button" *ngIf="!pilar.fieldTextoInterno">
                  <a>
                    <span>
                      {{'proximamente'| translate}}
                    </span>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="sec-iframe" *ngFor="let video of videos">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" [src]="video.fieldLinkVideo.uri | DomSafe" allowfullscreen>
            </iframe>
          </div>
        </div>

        <div class="sect-unt-parner mb-5 pb-3">
          <h3 class="wow animated fadeIn" data-wow-delay="0.2s">{{'titlebuscamosaliadosestrategicos'| translate}}</h3>
          <p class="pt-3 wow animated fadeIn" data-wow-delay="0.3s">{{'buscamosaliadosestrategicos'| translate}}</p>
          <!-- <h2>como partner?</h2> -->

          <div class="row content-card-partner">
            <div class="col-12 col-md-4 wow animated fadeIn" data-wow-delay="0.3s">
              <div class="card-partner d-flex flex-wrap align-content-center justify-content-center mx-3">
                <img class="img-fluid" src="../../../assets/img/logoaccenture.png" alt="">
              </div>
            </div>
            <div class="col-12 col-md-4 wow animated fadeIn" data-wow-delay="0.3s">
              <div class="card-partner d-flex flex-wrap align-content-center justify-content-center mx-3">
                <img class="img-fluid img-facemovil" src="../../../assets/img/Meta_Logo.png" alt="">
              </div>
            </div>
            <div class="col-12 col-md-4 wow animated fadeIn" data-wow-delay="0.3s">
              <div class="card-partner d-flex flex-wrap align-content-center justify-content-center mx-3">
                <img class="img-fluid" src="../../../assets/img/logos/mastercard-logo.png" alt="">
              </div>
            </div>
            <div class="col-12 col-md-4 wow animated fadeIn" data-wow-delay="0.3s">
              <div class="card-partner d-flex flex-wrap align-content-center justify-content-center mx-3">
                <img class="img-fluid" src="../../../assets/img/logos/vbm_blugrad01visa.png" alt="">
              </div>
            </div>
            <div class="col-12 col-md-4 wow animated fadeIn" data-wow-delay="0.3s">
              <div class="card-partner d-flex flex-wrap align-content-center justify-content-center mx-3">
                <img class="img-fluid" src="../../../assets/img/logos/RGB_PNG_statement_color_type2_m.png" alt=""
                  style="width: 170px; max-height: 70px;">
              </div>
            </div>
          </div>
          <p class="wow animated fadeIn" data-wow-delay="0.2s">
            {{'buscamosaliadosestrategicostexto'| translate}}</p>
        </div>

        <div class="input-group sec-suscribe">
          <form class="formEmail" [formGroup]="userOneEmails">
            <div class="form-group">
              <input type="email"
                [class.is-invalid]="primEmail.secondaryEmail.invalid && primEmail.secondaryEmail.touched"
                formControlName="secondaryEmail" class="form-control"
                placeholder="{{'paceholderemailcorpo'| translate}}" aria-label="Ingresa tu email corporativo"
                aria-describedby="button-addon3">
            </div>
          </form>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" id="button-addon3" (click)="sendEmail(2)">
              <span *ngIf="!isMobile || isTablet">{{'btnsolicitamasinformacion'| translate}}</span>
              <span *ngIf="isMobile  || isTablet">{{'btnsolicitamasinformacionmovil'| translate}}</span>
            </button>
          </div>
        </div>
        <div class="alert-message"
          *ngIf="(primEmail.secondaryEmail.invalid && primEmail.secondaryEmail.touched) || primEmail.secondaryEmail.dirty">
          <small *ngIf="primEmail.secondaryEmail.errors?.required" class="text-danger">{{ 'alertcalltoaction' |
            translate
            }}</small>
          <!--question mark(?) is a safe navigation operator-->
          <small *ngIf="primEmail.secondaryEmail.errors?.pattern" class="text-danger">{{ 'alertcalltoaction2' |
            translate
            }}</small>
        </div>
        <p class="pemail" *ngIf="(primEmail.secondaryEmail.valid) && sendEmailValidTwo && sendEmailEntrepreneurs">
          {{'infoenviomensaje'| translate}}
        </p>
        <p class="new-p wow animated fadeIn">
          {{'parrafo1'| translate}}
        </p>
        <p class="new-p wow animated fadeIn">
          {{'parrafo2'| translate}}
        </p>
      </div>
    </div>

    <div class="banner-two">
      <div class="content-absolute">
        <img class="img-fluid wow animated fadeIn" src="{{ 'imgmujereslogo' | translate}}" alt="">
      </div>
    </div>

    <div class="content-number">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="cads-numbers">
            <h2 class="wow animated fadeIn" data-wow-delay="0.2s">{{ 'testimonioyexperiencias' | translate }}</h2>
            <owl-carousel-o [options]="customOptions">
              <ng-container *ngFor="let testimonio of testimonial">
                <ng-template carouselSlide>
                  <div class="sect-testimonials">
                    <div class="row pb-3">
                      <div class="col-md-12 col-xl-4">
                        <img class="img-fluid img-pople wow animated fadeIn"
                          [ngClass]="{'color-orange': testimonio.fieldPerfil === 'Partner'}" data-wow-delay="0.2s"
                          [src]="testimonio.fieldImagenUsuaria.url" alt="">
                        <div class="sec-empr">
                          <span class="title-img-empr"
                            [ngClass]="{'back-orange': testimonio.fieldPerfil === 'Partner'}">{{testimonio.fieldPerfil}}</span>
                        </div>
                        <div class="card-img-empr" *ngIf="testimonio.fieldImagenEmpresa">
                          <img class="img-fluid img-enterpreis" [src]="testimonio.fieldImagenEmpresa.url" alt="">
                        </div>
                      </div>
                      <div class="col-md-12 col-xl-8 mxc-text">
                        <h3 class="black wow animated fadeIn" data-wow-delay="0.2s">{{ testimonio.title }}</h3>
                        <h3 class="purple wow animated fadeIn" data-wow-delay="0.2s"
                          [ngClass]="{'color-orange': testimonio.fieldPerfil === 'Partner'}">
                          {{ testimonio.fieldNombreEmpresa }}
                          ({{ testimonio.fieldPaisEmpresa }})
                        </h3>
                        <p class="wow animated fadeIn" data-wow-delay="0.2s">“{{
                          testimonio.fieldDescripcionEmpresa.processed
                          }}”</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>

    <div class="content-communities">
      <div class="circle1"></div>
      <div class="circle2"></div>
      <div class="card-communities">
        <h2>{{ 'comunidadempresarialtitle' | translate }}</h2>
        <p>{{ 'comunidadempresarialtext' | translate }}<a
            [href]="environmentProd+'/page/search?qt-search_tabs_container=3'" target="_blank"
            style="font-size: 1.25rem;">
            {{ 'comunidadempresarialtextlink' | translate }}</a></p>
        <div class="row pt-5 mt-2 content973" *ngFor="let comunidad of comunidad">
          <div class="col-md-6 col-12 pb-3">
            <h3 class="mujeresempreariashw">
              {{'mujeres' | translate}}<br>{{'empresarias' | translate}}
            </h3>
            <h4>
              +{{ comunidad.fieldTotal }}
              {{'comunidadempresarialnumeromujeresuno' | translate}}</h4>
            <div class="sect-content-img">
              <img class="img-fluid wow animated fadeIn" data-wow-delay="0.2s"
                *ngFor="let imagenes of comunidad.fieldWomanImage" [src]="imagenes.url" alt="">
            </div>
            <button class="btn btn-participate  wow animated fadeIn" data-wow-delay="0.2s" type="button">
              <a href="{{environmentProd}}{{'comunidadempresariallinkuno' | translate}}" target="_blank">
                {{'unete' | translate}}
              </a>
            </button>
          </div>
          <div class="col-md-6 col-12">
            <h3 class="mxmujeresap">{{ 'comunidadempresarialmujeresap' |
              translate }}</h3>
            <h4>+{{ comunidad.fieldTotalAp }}
              {{'comunidadempresarialnumeromujeresdos' | translate}}</h4>
            <div class="sect-content-img">
              <img class="img-fluid wow animated fadeIn" data-wow-delay="0.2s"
                *ngFor="let imagenesAP of comunidad.fieldWomanImageAp" [src]="imagenesAP.url" alt="">
            </div>
            <button class="btn btn-participate wow animated fadeIn" data-wow-delay="0.2s" type="button">
              <a href="{{environmentProd}}{{'comunidadempresariallinkdos' | translate}}" target="_blank">
                {{'unete' | translate}}
              </a>
            </button>
          </div>
        </div>

        <div class="row pt-5 mt-5 content973">
          <div class="col-md-6 col-12 pb-3">
            <h3 class="text-left mb-3">{{'conocenuestropodcast' | translate}}</h3>
            <p class="ptext mb-5">{{'conocenuestropodcasttext' | translate}}</p>
            <button class="btn btn-participate ml-0 wow animated fadeIn" data-wow-delay="0.2s" type="button">
              <a href="{{environmentProd}}{{'footer-aourpod'| translate}}" target="_blank">
                {{'btnescuchaahora' | translate}}
              </a>
            </button>
          </div>
          <div class="col-md-6 col-12">
            <button class="btn btn-link d-block text-right" type="button">
              <a href="{{environmentProd}}{{'footer-aourpod'| translate}}" target="_blank">
                <img class="img-fluid" src="../../../assets/img/imgvideolink.png" alt="">
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="content-webinars">
      <h3 class="wow animated fadeIn" data-wow-delay="0.2s">{{'webinarsycontenidos' | translate}}</h3>
      <div class="d-flex justify-content-end">

        <button class="btn btn-todos" type="button">
          <a href="{{environmentProd}}{{'linkmorewebinar' | translate}}" target="_blank">
            {{'vertodoswebinars' | translate}}
          </a>
        </button>
      </div>

      <owl-carousel-o [options]="customOptionsWebinars">
        <ng-container *ngFor="let  webinar of webinars">
          <ng-template carouselSlide>
            <div class="row">
              <div class="col-md-12 col-12">
                <a [href]="webinar.fieldLinkWebinar.uri | translate" target="_blank">
                  <div class="card-webinars wow animated fadeIn m-2" data-wow-delay="0.3s">
                    <!-- <img class="img-fluid" [src]="webinar.fieldImagenWebinar.url | translate" alt=""> -->
                    <div class="webinar-image"
                      [ngStyle]="{'background-image':'url(' + webinar.fieldImagenWebinar.url + ')'}">
                    </div>
                    <div class="content-text">
                      <h3>{{webinar.fieldAutorWebinar | translate}}</h3>
                      <span *ngIf="webinar.fieldFechaWebinar !== null">{{webinar.fieldFechaWebinar.value |
                        translate}}</span>
                      <!-- <p>{{webinar.fieldDescripcionWebinar.processed | translate}}</p> -->
                      <p style="width: 100%; height: 105px;" ellipsis
                        [ellipsis-content]="webinar.fieldDescripcionWebinar.processed | translate"></p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>

    </div>


    <!-- Anuncios  -->
    <div class="content-webinars announcements">
      <h3 class="wow animated fadeIn" data-wow-delay="0.2s">{{'anuncion.compra' | translate}}</h3>
      <div class="d-flex justify-content-end">
        <button class="btn btn-todos" type="button">
          <a href="{{environmentProd}}{{'linkmoreannouncements' | translate}}" target="_blank">
            {{'vertodoswebinars' | translate}}
          </a>
        </button>
      </div>
      <owl-carousel-o [options]="customOptionsWebinars">
        <ng-container *ngFor="let item of anuncios">
          <ng-template carouselSlide>
            <div class="row">
              <div class="col-md-12 col-12">
                <a [href]="item.fieldLink.uri | translate" target="_blank">
                  <div class="card-webinars wow animated fadeIn m-2" data-wow-delay="0.3s">
                    <!-- <img class="img-fluid" [src]="webinar.fieldImagenWebinar.url | translate" alt=""> -->
                    <div class="sect-img">
                      <div class="webinar-image"
                        [ngStyle]="{'background-image':'url(' + item.fieldLogoComprador.url + ')'}">
                      </div>
                    </div>
                    <div class="content-text">
                      <h3>{{item.title | translate}}</h3>
                      <span>{{item.fieldNombreComprador | translate}}</span>
                      <span>{{item.fieldSector | translate}}</span>
                      <div class="content-more-info">
                        <p>
                          <img class="img-fluid" src="../../../assets/img/time.jpg" alt="">
                          {{'cierrael' | translate}} {{item.fieldFechaCierreAnuncio | translate}}
                        </p>
                        <p>
                          <img class="img-fluid" src="../../../assets/img/donde.jpg" alt=""
                            style=" max-width: 19px; height: 21px; ">
                          {{item.fieldPais | translate}}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>


    </div>


    <div class="sect-partners">
      <h3 class="wow animated fadeIn" data-wow-delay="0.2s">{{'title100kchallenge' | translate}}</h3>
      <p>{{'description100kchallenge' | translate}}

        <a href="{{environmentProd}}{{'100kchallengelink' | translate}}" target="_blank">{{'link100kchallenge' |
          translate}}</a>
      </p>
      <div class="row">
        <div class="col-md-3 col-12" *ngFor="let item of parners">
          <div class="card-partners d-flex flex-wrap align-content-center wow animated fadeIn" data-wow-delay="0.3s">
            <img class="img-fluid " [src]="item.fieldLogoimg.url" alt="{{item.title}}">
          </div>
        </div>
      </div>
    </div>
    <div class="sect-can-be-part" *ngIf="hiddenMenuFooter">
      <div class="row">
        <div class="col-md-3 col-12 d-flex flex-wrap align-content-center">
          <img class="img-fluid" src="../../../assets/img/logo-purple.png" alt="">
        </div>
        <div class="col-md-8 col-12">
          <h3 class="wow animated fadeIn" data-wow-delay="0.2s">
            {{ 'cuentatuexperiencia' | translate }}
          </h3>
          <p>{{ 'cuentatuexperiencia2' | translate }}</p>
          <div class="input-group sec-suscribe">
            <form class="formEmail" [formGroup]="userOneEmails">
              <div class="form-group">
                <input type="email" [class.is-invalid]="primEmail.thirdEmail.invalid && primEmail.thirdEmail.touched"
                  formControlName="thirdEmail" class="form-control" placeholder="{{'paceholderemailcorpo'| translate}}"
                  aria-label="Ingresa tu email corporativo" aria-describedby="button-addon3">
              </div>
            </form>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" id="button-addon3" (click)="joinEmail()">
                <a target="_blank" style="color:white;" >
                  {{ 'uneteahora' | translate }}
                </a>
              </button>
            </div>
          </div>
          <div class="alert-message"
            *ngIf="(primEmail.thirdEmail.invalid && primEmail.thirdEmail.touched) || primEmail.thirdEmail.dirty">
            <small *ngIf="primEmail.thirdEmail.errors?.required" class="text-danger">{{ 'alertcalltoaction' | translate
              }}</small>
            <!--question mark(?) is a safe navigation operator-->
            <small *ngIf="primEmail.thirdEmail.errors?.pattern" class="text-danger">{{ 'alertcalltoaction2' | translate
              }}</small>
          </div>
          <p class="pemail" *ngIf="(primEmail.thirdEmail.valid) && sendEmailValidThird">{{'infoenviomensaje' |
            translate}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="offset <= 5200">
    <div class="dropdown-bootom center">
      <button class="btn btn-secondary dropdown-toggle" type="button" (click)="scrollToElement()">
        Scroll
      </button>
    </div>
  </div>
  <app-footer *ngIf="hiddenMenuFooter"></app-footer>
</div>
