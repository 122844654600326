import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WINDOW_PROVIDERS } from "./core/services/window.service";
import { ConfigAppProvider } from './providers/configApp.provider';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgwWowModule } from 'ngx-wow';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConnectamericasComponent } from './mudules/connectamericas/connectamericas.component';
import { HomeComponent } from './mudules/home/home.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavMenuComponent } from './shared/components/nav-menu/nav-menu.component';
import { GraphQLModule } from './graphql.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';

// PIPES
import { DomSafePipe } from './core/pipes/dom-safe.pipe';
import { RouterModule } from '@angular/router';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { EllipsisModule } from 'ngx-ellipsis';
import { PilarInsideComponent } from './mudules/pilar-inside/pilar-inside.component';
import { FooterPilarComponent } from './shared/components/footer-pilar/footer-pilar.component';
import { NavMenuPilarComponent } from './shared/components/nav-menu-pilar/nav-menu-pilar.component';

@NgModule({
  declarations: [
    AppComponent,
    ConnectamericasComponent,
    HomeComponent,
    NavMenuComponent,
    FooterComponent,
    DomSafePipe,
    PilarInsideComponent,
    FooterPilarComponent,
    NavMenuPilarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApolloModule,
    RouterModule,
    CarouselModule,
    GraphQLModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgwWowModule,
    EllipsisModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: 'https://live-mujeres-connectamerica.pantheonsite.io/graphql',
          }),
        };
      },
      deps: [HttpLink],
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    WINDOW_PROVIDERS,
    ConfigAppProvider,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// tslint:disable-next-line:typedef
export function HttpLoaderFactory(http: HttpClient) {
  const urlDinamicPrd = environment.dictionaryUrl;
  return new TranslateHttpLoader(http, urlDinamicPrd, '.json');
}
