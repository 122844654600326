<footer>
  <div class="circle1" [ngClass]="{'position-absolute': activeAbsolute}"></div>
  <div class="sect-footer d-flex flex-wrap align-content-center h-100">
    <div class="logo-white">
      <a [href]="environmentProd" target="_parent">
        <img class="" src="../../../../assets/img/logo_home_ca.png" alt="">
      </a>
    </div>
    <div class="row w-100  m-auto">
      <div class="col-div-footer col-12 col-md-2">
        <div class="sect-title">
          <h2>{{ 'seleccioneidioma' | translate}}</h2>
        </div>
        <div class="lang-selector">
          <div class="current">
            <a (click)="useLanguage('es')">
              {{ 'espanol2' | translate}}
            </a>
          </div>
          <ul class="menu">
            <li><a (click)="useLanguage('en')">{{ 'ingles2' | translate}}</a></li>
            <li><a (click)="useLanguage('pt')">{{ 'portugues2' | translate}}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-div-footer col-12 col-md-3">
        <div class="sect-title">
          <h2>{{ 'legal' | translate}}</h2>
        </div>
        <ul class="menu">
          <li><a href="{{environmentProd}}{{'footer-terminos'| translate}}" target="_parent" rel="popup">{{
              'terminoscondiciones' | translate}}</a></li>
          <li><a href="{{environmentProd}}{{'footer-privacidad'| translate}}" target="_parent" rel="popup">{{
              'politicaprivacidad' | translate}}</a></li>
        </ul>
      </div>
      <div class="col-div-footer col-12 col-md-3">
        <div class="sect-title">
          <h2>{{ 'sobrenosotros' | translate}}</h2>
        </div>
        <ul class="menu">
          <li><a href="{{environmentProd}}{{'footer-about'| translate}}" target="_parent" rel="popup">{{
              'acercadeconnectamericas' | translate}}</a></li>
          <li><a href="{{environmentProd}}{{'footer-aourpod'| translate}}" target="_parent" rel="popup">{{
              'nuestrospodcast' | translate}}</a></li>
        </ul>
      </div>
      <div class="col-div-footer col-12 col-md-2">
        <div class="sect-title">
          <h2>{{ 'ayuda' | translate}}</h2>
        </div>
        <ul class="menu">
          <li>
            <a href="{{environmentProd}}{{'footer-helpcenter'| translate}}" target="_blank" rel="noopener noreferrer">
              {{ 'centroayuda' | translate}}
            </a>
          </li>
          <li><a href="{{environmentProd}}{{'footer-frecuentquestion'| translate}}" target="_parent">{{
              'preguntasfrecuentes' |
              translate}}</a></li>
          <li><a (click)="contactClick()" class="md-trigger md-setperspective" target="_parent">{{ 'contacto' |
              translate}}</a></li>
        </ul>
      </div>
      <div class="col-div-footer col-12 col-md-2">
        <div id="social-networks" class="block static">
          <div class="sect-title">
            <h2>{{ 'siguenos' | translate}}</h2>
          </div>
          <div class="block-content">
            <ul class="menu">
              <li>
                <a class="yt" title="YouTube" [href]="'https://www.youtube.com/user/ConnectAmericas'" target="_blank"
                  rel="noopener noreferrer">YouTube</a>
              </li>
              <li>
                <a class="ln" title="Linkedin" [href]="'https://www.linkedin.com/company/connectamericas/'"
                  target="_blank" rel="noopener noreferrer">LinkedIn</a>
              </li>
              <li>
                <a class="fb" title="Facebook" [href]="'https://www.facebook.com/ConnectAmericas'" target="_blank"
                  rel="noopener noreferrer">Facebook</a>
              </li>
              <li><a class="tw" title="Twitter" [href]="'https://twitter.com/connectamericas'" target="_blank"
                  rel="noopener noreferrer">Twitter</a></li>
              <li><a class="tw" title="Instagram" [href]="'https://www.instagram.com/connectamericas/'" target="_blank"
                  rel="noopener noreferrer">Instagram</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="row m-auto">
      <div class="col-md-6 col-12">
        <div class="footer-info d-flex align-items-center h-100">
          <a href="http://www.iadb.org/es/" target="_blank" rel="noopener noreferrer" id="footer-idblogo"
            class="lang-text">
            <p>1300 New York Avenue, N.W. Washington, D.C. 20577, USA<br>© 2015 Inter-American Development Bank</p>
          </a>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="content-support-for d-flex justify-content-end align-items-center">
          <span>{{ 'conelapoyode' | translate}}</span>
          <a class="google" href="http://www.google.com" target="_blank" rel="noopener noreferrer">Google</a>
          <a class="dhl" href="https://www.simplydhl.com/connectamericas/es" target="_blank"
            rel="noopener noreferrer">DHL</a>
          <a class="sealand" href="http://www.sealand.com" target="_blank" rel="noopener noreferrer">SeaLand</a>
          <a class="facebook" href="http://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a class="master-card" href="http://www.mastercard.com" target="_blank"
            rel="noopener noreferrer">MasterCard</a>
        </div>
      </div>

    </div>
  </div>
</footer>
