import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigAppProvider } from 'src/app/providers/configApp.provider';
import { environment } from 'src/environments/environment.prod';
import { WINDOW } from 'src/app/core/services/window.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  isMobile = false;
  isTablet = false;

  langVisible: boolean | undefined;
  defaultSpanish = false;
  defaultEnglis = false;
  defaultPortuguese = false;
  activeLang = 'en';
  environmentProd!: string;
  offset = 0;

  constructor(
              private deviceService: DeviceDetectorService,
              private router: Router,
              private translate: TranslateService,
              private configApp: ConfigAppProvider,
              private route: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document,
              @Inject(WINDOW) private window: Window,
              ) {
      this.route.queryParamMap.subscribe((queryParams: any) => {
        this.activeLang = 'en';
        this.initialConfiguration(queryParams);
      });
  }
  @HostListener('window:scroll', [])
  // tslint:disable-next-line:typedef
  onWindowScroll() {
    this.offset = this.window.innerWidth;
  }

  initialConfiguration(queryParams: any): void {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    let currentLanguage: any = window.navigator.language.substr(0, 2);
    let langStorage: any = 'en';
    try {
      langStorage = (window.localStorage) ? window.localStorage.getItem('lang') : 'en';
    } catch (error) {}
    if (langStorage) {
      const langVP = (langStorage !== undefined && langStorage !== null) ?
        langStorage : currentLanguage;
      currentLanguage = (langVP === 'pt') ? 'pt' : langVP?.toLocaleLowerCase();
      this.useLanguage(langStorage || '{}');
      if (currentLanguage.indexOf('en') !== -1 || currentLanguage.indexOf('es') !== -1 ||
        (currentLanguage.indexOf('pt') !== -1 || currentLanguage.indexOf('pr') !== -1 || langStorage === 'pr'  ) ) {
          if (currentLanguage.indexOf('en') !== -1) {
            this.defaultSpanish = true;
            this.defaultEnglis = false;
            this.defaultPortuguese = true;
            this.activeLang = 'en';
          } else if (currentLanguage.indexOf('pt') !== -1 || currentLanguage.indexOf('pr') !== -1
            || langStorage === 'pr') {
            this.defaultSpanish = true;
            this.defaultEnglis = true;
            this.defaultPortuguese = false;
            this.activeLang = 'pt';
          } else if (currentLanguage.indexOf('es') !== -1) {
            this.defaultSpanish = false;
            this.defaultEnglis = true;
            this.defaultPortuguese = true;
            this.activeLang = 'es';
          }
        }
      if (queryParams.params !== undefined &&
        queryParams.params.language !== undefined && queryParams.params.language !== null &&
        queryParams.params.language !== 'null') {
        const langV = queryParams.params.language.toLowerCase();
        this.activeLang = (langV === 'pt') ? 'pt' : langV;
        try {
          if (window.localStorage) {
            window.localStorage.setItem('lang', this.activeLang);
          }
        } catch (error) {}
      }
      try {
          if (window.localStorage) {
            window.localStorage.setItem('lang', this.activeLang);
          }
      } catch (error) {}
      this.translate.setDefaultLang(this.activeLang);
      // }
    } else {
      this.defaultSpanish = false;
      this.defaultEnglis = true;
      this.defaultPortuguese = true;
      this.activeLang = 'en';
    }
  }

  ngOnInit(): void {
    this.environmentProd = environment.urlProd;
    this.onWindowScroll();

  }

  useLanguage(langV: string): void  {
    this.configApp.language = langV.toUpperCase();
    if (langV === 'es') {
      this.defaultSpanish = false;
      this.defaultEnglis = true;
      this.defaultPortuguese = true;
    } else if (langV === 'en') {
      this.defaultSpanish = true;
      this.defaultEnglis = false;
      this.defaultPortuguese = true;
    } else if (langV === 'pt') {
      this.defaultSpanish = true;
      this.defaultEnglis = true;
      this.defaultPortuguese = false;
    }
    this.activeLang = langV;
    try {
      if (window.localStorage) {
        window.localStorage.setItem('lang', langV);
      }
    } catch (error) {}
    this.translate.use(langV);
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      // this.router.navigate([currentUrl, {
      //   lang: (langV === 'pt_br') ? 'pt' :  langV,
      // }]);
    });
  }

  validarResponsiveIdioma(): boolean {
    return (screen.width > 991) ? false : true;
  }

  toggleButtonTwo(): void {
    (document.querySelector('.sidebar') as HTMLElement).style.overflow = 'visible';
    (document.querySelector('.sidebar') as HTMLElement).classList.add('active');
    (document.querySelector('.overlay') as HTMLElement).classList.add('active');
  }

  quitActive(): void {
    (document.querySelector('.sidebar') as HTMLElement).classList.remove('active');
    (document.querySelector('.overlay') as HTMLElement).classList.remove('active');
  }

  // tslint:disable-next-line:typedef
  logInPost() {
    setTimeout(() => {
      window.parent.postMessage('openLogin', this.environmentProd);
    }, 1000);
  }
}
