import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';

// Interfaces

@Injectable()
export class ConfigAppProvider {

  language: string;
  language$: BehaviorSubject<string>;
  languageLocal = 'en' || '{}';


  constructor() {
    this.language = this.languageLocal.toUpperCase();
    this.language$ = new BehaviorSubject( this.language );
  }

}
