import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private emailsContentsCredentials = {
    'es': {
      from: 'mujeres@connectamericas.com',
      fromName: 'Mujeres ConnectAmericas',
    },
    'en': {
      from: 'women@connectamericas.com',
      fromName: 'Women ConnectAmericas',
    },
    'pt': {
      from: 'ulheres@connectamericas.com',
      fromName: 'Mulheres ConnectAmericas',
    }
  };

  read_prop(obj: any, prop: string) {
    return obj[prop];
  }
  constructor(private http: HttpClient) { }

  sendEmailAlliances(to: string, languageV: string) {
    let langStorage: any = languageV;
    try {
      langStorage = (window.localStorage) ? window.localStorage.getItem('lang') : languageV;
    } catch (error) {
    }
    const lang: any = (langStorage !== null) ?
      ((langStorage === 'pt') ? 'pt' : langStorage) : 'es';
    const from = this.read_prop(this.emailsContentsCredentials, lang).from;
    const fromName = this.read_prop(this.emailsContentsCredentials, lang).fromName;
    return this.http.post(environment.apiEmail +
      `/EmailAliados/${lang}`,
    {
      from: from,
      fromName: fromName,
      to: to,
    });
  }

  sendEmailEntrepreneurs(to: string, languageV: string) {
    let langStorage: any = languageV;
    try {
      langStorage = (window.localStorage) ? window.localStorage.getItem('lang') : languageV;
    } catch (error) {
    }
    let lang: any = languageV;
    try {
      lang = (langStorage !== null) ?
        ((langStorage === 'pt') ? 'pt' :
         langStorage) : 'es';
    } catch (error) {
    }
    const from = this.read_prop(this.emailsContentsCredentials, lang).from;
    const fromName = this.read_prop(this.emailsContentsCredentials, lang).fromName;
    return this.http.post(environment.apiEmail +
      `/EmailEmpresarias/${lang}`,
    {
      from: from,
      fromName: fromName,
      to: to,
    });
  }
}
