import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, DoCheck, ElementRef, HostListener, Inject, KeyValueDiffer, KeyValueDiffers,
   OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { EmailService } from 'src/app/core/services/email.service';
import { HomeService } from 'src/app/core/services/home.service';
import { WINDOW } from 'src/app/core/services/window.service';
import { ConfigAppProvider } from 'src/app/providers/configApp.provider';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { ContactService } from 'src/app/core/services/contact.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy  {

  submitted = false;
  component: any;

  isMobile = false;
  isTablet = false;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 7900,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: true,
    margin: 15,
    navSpeed: 700,
    dotsSpeed: 700,
    // tslint:disable-next-line:quotemark
    navText: ["<em class='fa fa-chevron-left'></em>", "<em class='fa fa-chevron-right'></em>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  };

  customOptionsWebinars: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 7900,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: true,
    margin: 1,
    navSpeed: 700,
    dotsSpeed: 700,
    // tslint:disable-next-line:quotemark
    navText: ["<em class='fa fa-chevron-left'></em>", "<em class='fa fa-chevron-right'></em>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 4
      }
    },
  };

  activeLogin!: string;

  userOneEmails = new UntypedFormGroup({
    primaryEmail: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')]),
    secondaryEmail: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')]),
    thirdEmail: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')]),
  });
  // tslint:disable-next-line:typedef
  get primEmail() {
    return this.userOneEmails.controls;
    }
  sendEmailValid = false;
  sendEmailValidTwo = false;
  sendEmailValidThird = false;
  sendEmailAlliances = false;
  sendEmailEntrepreneurs = false;


  pilar: any;
  pilarOpen = false;
  parners: any;
  parnersOpen = false;

  testimonial: any;
  testimonialOpen = false;

  comunidad: any;
  comunidadOpen = false;

  webinars: any;
  webinarsOpen: any;

  videos: any;
  videosOpen: any;

  anuncios: any;
  anunciosOpen: any;

  @ViewChild('main')
  main!: ElementRef;

  offset = 0;
  hiddenMenuFooter = true;

  order!: any;
  scrollContainer: any;
  items: any[] = [];
  isNearBottom: boolean | undefined;
  webinarsArr: any[] = [];
  private configAppDiffer: KeyValueDiffer<string, any>;
  activeAbsolute = false;
  environmentProd!: string;
  language!: any;
  lang: any = 'en';

  constructor(
    private route: Router,
    private deviceService: DeviceDetectorService,
    private homeService: HomeService,
    private configApp: ConfigAppProvider,
    private differs: KeyValueDiffers,
    private emailService: EmailService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    public element: ElementRef,
    private routez: ActivatedRoute,
    private contactService: ContactService
    ) {
      try {
        this.route.events.subscribe((event: any) => {
          if (typeof event.url !== 'undefined') {
            if (event.url.includes('lang')) {
              const urlString = event.url;
              const ulr = new URL('https://tester' + urlString);
              const lang = ulr.searchParams.get('lang');
              this.lang = lang;
            }
          }
        });
      } catch (error) {
      }
      this.configAppDiffer = this.differs.find(this.configApp).create();
      this.isMobile = this.deviceService.isMobile();
      this.isTablet = this.deviceService.isTablet();

      this.routez.queryParamMap.subscribe((queryParams: any) => {
        this.detectLaguaje(queryParams);
      });
    }

  // tslint:disable-next-line:typedef
  ngAfterViewInit() {
        // const isOldIE = (navigator.userAgent.indexOf('MSIE') !== -1); // Detect IE10 and below
        // const components = iframeResizer({
        //   log: true,
        //   scrolling: true,
        //   resizeFrom: 'child',
        //   minHeight: document.body.scrollHeight,
        //   heightCalculationMethod: isOldIE ? 'max' : 'lowestElement'
        // }, '#myIframe');

        // /* save component reference so we can close it later */
        // this.component = components && components.length > 0 ? components[0] : null;
      setTimeout(() => {
          this.activeAbsolute = true;
        }, 1000);
    }

    ngOnDestroy(): void {
        // if (this.component && this.component.iFrameResizer) {
        //     this.component.iFrameResizer.close();
        // }
    }


  ngOnInit(): void {
    this.getPilar();
    this.getParners();
    this.getWebinarsArr();
    this.timeOverflow();
    this.getWebinars();
    this.getVideos();
    this.getAnuncios();
    this.getRoterGuest();
    this.environmentProd = environment.urlProd;
  }

  ngDoCheck(): void {
    const changes = this.configAppDiffer.diff(this.configApp);
    if (changes) {
      this.routez.queryParamMap.subscribe((queryParams: any) => {
        this.detectLaguaje(queryParams);
      });
      this.getPilar();
      this.getWebinars();
      this.getVideos();
      this.getAnuncios();

    }
  }

  @HostListener('window:scroll', [])
  // tslint:disable-next-line:typedef
  onWindowScroll() {
    this.offset = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
  }


  // tslint:disable-next-line:typedef
  detectLaguaje(queryParams: any): void {
    let langStorage: any = 'en';
    try {
      langStorage = (window.localStorage) ? window.localStorage.getItem('lang') : 'en';
    } catch (error) {
    }
    let currentLanguage: any = window.navigator.language.substr(0, 2);
    if (langStorage || currentLanguage) {
      const langVP = (langStorage !== undefined && langStorage !== null) ?
        langStorage
        : currentLanguage;
      currentLanguage = (langVP === 'pt') ? 'pt' : langVP?.toLocaleLowerCase();
      if (currentLanguage.indexOf('en') !== -1 || currentLanguage.indexOf('es') !== -1 ||
        (currentLanguage.indexOf('pt') !== -1 || currentLanguage.indexOf('pr') !== -1 || langStorage === 'pr')) {
        if (currentLanguage.indexOf('en') !== -1) {
          this.language = 'en';
        } else if (currentLanguage.indexOf('pt') !== -1 || currentLanguage.indexOf('pr') !== -1 || langStorage === 'pr') {
          this.language = 'pt';
        } else if (currentLanguage.indexOf('es') !== -1) {
          this.language = 'es';
        }
      }
      if (queryParams.params !== undefined &&
        queryParams.params.language !== undefined && queryParams.params.language !== null &&
        queryParams.params.language !== 'null') {
        const langV = queryParams.params.language.toLowerCase();
        this.language = (langV === 'pt') ? 'pt' : langV;
        try {
          if (window.localStorage) {
            window.localStorage.setItem('lang', this.language);
          }
        } catch (error) {
        }
      }
      try {
        if (window.localStorage) {
          window.localStorage.setItem('lang', this.language);
        }
      } catch (error) {
      }
      // }
    } else {
      this.language = 'en';
    }
  }

  getRoterGuest(){
    try {
        if (window.localStorage) {
          window.localStorage.removeItem('guest');
        }
    } catch (error) {}
    this.route.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('guest')) {
          const urlString = event.url;
          const ulr = new URL('https://tester' + urlString);
          const guest = ulr.searchParams.get('guest');
          if (guest === 'false') {
            try {
              if (window.localStorage) {
              window.localStorage.setItem('guest', 'false');
              }
            } catch (error) {}
            this.hiddenMenuFooter = false;
          } else {
            try {
              if (window.localStorage) {
              window.localStorage.setItem('guest', 'true');
              }
            } catch (error) {}
            this.hiddenMenuFooter = true;
          }
        } else {
          this.menuChangeIfLocalStorageIsActive();
        }
      } else {
        this.menuChangeIfLocalStorageIsActive();
      }
    });

  }

  scrollToElement(): void {
    let offsetY;
    if (this.offset <= 500 ){
      offsetY = 500;
    } else if (this.offset >= 501) {
      offsetY = 600;
    }
    window.scrollBy({
      top: offsetY, // could be negative value
      left: 0,
      behavior: 'smooth'
    });
  }

  // tslint:disable-next-line:typedef
  getPilar() {
    if (this.language === 'pt'){
      this.language = 'pt_br';
    }
    this.homeService.getPilar( '', 100, 0,
      (pilares: any, total: any) => {
        this.pilar = pilares;
        this.pilarOpen = true;
      }, this.language || '{}'
    );
    this.homeService.getTestimonial(
      '',
      100,
      0,
      (testimonios: any, total: any) => {
        this.testimonial = testimonios;
        this.testimonialOpen = true;
      }, this.language || '{}'
    );
    this.homeService.getComunidades(
      '',
      100,
      0,
      (comunidades: any, total: any) => {
        this.comunidad = comunidades;
        this.comunidadOpen = true;
      }
    );
  }

  // tslint:disable-next-line:typedef
  getWebinars() {
    this.homeService.getWebinars(
      '', 100, 0,
      (webinars: any, total: any) => {
        this.webinars = webinars;
        this.webinarsOpen = true;
      }, this.language || '{}'
    )
  }

  // tslint:disable-next-line:typedef
  getVideos() {
    this.homeService.getVideos(
      '', 100, 0,
      (videos: any, total: any) => {
        this.videos = videos;
        this.videosOpen = true;
      }, this.language || '{}'
    );
  }

  // tslint:disable-next-line:typedef
  getParners(){
    this.homeService.getParners(
      '', 100, 0,
      (parners: any, total: any) => {
        this.parners = parners;
        this.parnersOpen = true;
      }
    );
  }

  getAnuncios(){
    this.homeService.getAnuncios(
      '', 100, 0,
      (anuncios: any, total: any) => {
        this.anuncios = anuncios;
        this.anunciosOpen = true;
      }, this.language || '{}'
    );
  }

  // tslint:disable-next-line:typedef
  sendEmail(numberIndex: number) {
    this.sendEmailValid = false;
    this.sendEmailValidTwo = false;
    this.sendEmailValidThird = false;
    if (numberIndex === 1) {
      this.sendEmailValid = true;

      const form = {
        data: {
          type: 'node--conoce_mas',
          attributes: {
            title: 'Formulario Conoce Más',
            field_email_conoce: this.userOneEmails.value.primaryEmail,
          },
        },
      };

      this.contactService.sendContactConoce(form).subscribe((result: any) => {
        if (result.data.id == null) {
          console.error('error on insert form');
        }
      });
    } else if (numberIndex === 2) {
      this.sendEmailValidTwo = true;

      const form = {
        data: {
          type: 'node--solicita_informacion',
          attributes: {
            title: 'Formulario Solicita Información',
            field_email_informacion: this.userOneEmails.value.secondaryEmail,
          },
        },
      };

      this.contactService.sendContactInformacion(form).subscribe((result: any) => {
        if (result.data.id == null) {
          console.error('error on insert form');
        }
      });
    } else if (numberIndex === 3) {
      this.sendEmailValidThird = true;
    }
    this.sendEmailToMore(numberIndex);
  }

  // tslint:disable-next-line:typedef
  sendEmailToMore(index: number) {
    this.sendEmailAlliances = false;
    this.sendEmailEntrepreneurs = false;
    switch (index) {
      case 1:
        if (this.sendEmailValid) {
          this.emailService.sendEmailEntrepreneurs(this.primEmail.primaryEmail.value, this.lang).subscribe((x) => {
            this.primEmail.primaryEmail.setValue('');
            this.primEmail.primaryEmail.setErrors(null);
            if (x) {
              this.sendEmailAlliances = true;
            }
          });
        }
        break;
      case 2:
        if (this.sendEmailValidTwo) {
          this.emailService.sendEmailAlliances(this.primEmail.secondaryEmail.value, this.lang).subscribe((x) => {
            this.primEmail.secondaryEmail.setValue('');
            this.primEmail.secondaryEmail.setErrors(null);
            if (x){
              this.sendEmailEntrepreneurs = true;
            }
          });
        }
        break;
    }
  }

  // tslint:disable-next-line:typedef
  getWebinarsArr(){
    this.webinarsArr = [
    {
        autor: 'webinarsautor1',
        fecha: 'webinarsdate1',
        description: 'webinarsdescrp1',
        img: 'webinarsurlimg1',
        urlink: 'webinarsurllink1'
    },
    {
      autor: 'webinarsautor2',
      fecha: 'webinarsdate2',
      description: 'webinarsdescrp2',
      img: 'webinarsurlimg2',
      urlink: 'webinarsurllink2'
    },
    {
      autor: 'webinarsautor3',
      fecha: 'webinarsdate3',
      description: 'webinarsdescrp3',
      img: 'webinarsurlimg3',
      urlink: 'webinarsurllink3'
    },
    {
      autor: 'webinarsautor4',
      fecha: 'webinarsdate4',
      description: 'webinarsdescrp4',
      img: 'webinarsurlimg4',
      urlink: 'webinarsurllink4'
    },
  ];
  }


  // tslint:disable-next-line:typedef
  timeOverflow(){
    // (document.querySelector('html') as HTMLElement).style.overflowX = 'hidden';
    // setTimeout( () => {
    //   (document.querySelector('html') as HTMLElement).style.overflowX = 'revert';
    //  }, 1000);
  }

  private menuChangeIfLocalStorageIsActive(): void {
    if (
      window.localStorage &&
      window.localStorage.getItem('guest') !== 'undefined' &&
      window.localStorage.getItem('guest') !== null &&
      window.localStorage.getItem('guest') !== 'null'
    ) {
      let guest: any = false;
      try {
        if (window.localStorage) {
          guest = window.localStorage.getItem('guest');
        }
      } catch (error) {
      }
      this.hiddenMenuFooter = (guest === 'true');
    } else {
      this.hiddenMenuFooter = true;
    }
  }

  // tslint:disable-next-line:typedef
  joinEmail(){
    const form = {
      data: {
        type: 'node--unete_ahora',
        attributes: {
          title: 'Formulario Únete Ahora',
          field_email_unete: this.userOneEmails.value.thirdEmail,
        },
      },
    };

    this.contactService.sendContactUnete(form).subscribe((result: any) => {
      if (result.data.id == null) {
        console.error('error on insert form');
      }
    });

    if (this.primEmail.thirdEmail.status === 'VALID') {
      window.parent.postMessage({
        action: 'openRegister',
        email: this.primEmail.thirdEmail.value
      }, this.environmentProd);

    }
  }

}
