<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="!isMobile || offset >= 992">
  <div class="max-nav-center">
    <a class="navbar-brand" [href]="environmentProd" target="_parent" routerLinkActive="active">
      <img class="img-fluid" src="../../../../assets/img/logo_home_ca.svg" alt="">
    </a>
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->

    <div class="collapse navbar-collapse " id="navbarSupportedContent">
      <ul class="navbar-nav  ml-auto">


        <li class="nav-item">

          <button class="btn btn-link" type="button">
            <a href="{{environmentProd}}{{'buscarnav' | translate}}" target="_blank">
              <em class="fa fa-search"></em>
              {{'buscar' | translate}}
            </a>
          </button>
        </li>

        <li class="nav-item menu-connect-americas">
          <a class="nav-link dropdown-toggle" routerLinkActive="active" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-expanded="false">
            {{'porqueconectaamericas' | translate}}
          </a>
          <div class="dropdown-menu dropdown-menu-connect" aria-labelledby="navbarDropdown">

            <div id="submenu-top-ul">
              <h5>{{'features' | translate }}</h5>
              <ul>
                <li><a href="{{environmentProd}}{{'linkanunciocompra' | translate}}" target="_parent">
                    {{'purchasingannouncements' | translate}}</a>
                </li>
                <li><a href="{{environmentProd}}{{'linkdireccionempresas' | translate}}" target="_parent">
                    {{'connectedcompanies' | translate}}
                  </a>
                </li>
                <li><a href="{{'linkcapacitacion' | translate}}" target="_parent">
                    {{'training' | translate}}</a>
                </li>
                <li><a href="{{environmentProd}}{{'linkcomunidadesnegocio' | translate}}" target="_parent">
                    {{'businesscommunities' | translate}}
                  </a>
                </li>
                <li><a href="{{environmentProd}}{{'linkeventosempresa' | translate}}" target="_parent">
                    {{'events' | translate}}</a>
                </li>
                <li><a href="{{environmentProd}}{{'linkserviciofinanciero' | translate}}" target="_parent">
                    {{'financialservices' |translate}}
                  </a>
                </li>
                <li>
                  <a href="{{environmentProd}}{{'linkhubpodcasts' | translate}}" target="_parent">
                    {{'elhubpodcasts' | translate}}
                  </a>
                </li>
                <li><a href="{{environmentProd}}{{'linkmujeresconectamericas' | translate}}" target="_parent">
                    {{'connectamericaswomen' | translate}}</a>
                </li>
                <li><a href="{{environmentProd}}{{'linkcasoexito' | translate}}" target="_parent">
                    {{'successstories' | translate}}</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item active">
          <a class="nav-link" [href]="environmentProd+'/es/user/register'" routerLinkActive="active">
            {{'uneteahora' | translate}}
          </a>
        </li>
        <li class="nav-item">
          <button class="btn btn-navmenu" type="button" (click)="logInPost()">
            <a target="_blank">
              {{'iniciarsesion' | translate}}
            </a>
          </button>
        </li>
        <li class="nav-item dropdown w-55px">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span>
              {{'language' | translate}}
            </span>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a *ngIf="defaultSpanish" class="dropdown-item inside" (click)="useLanguage('es')">{{'espanol' |
              translate}}</a>
            <a *ngIf="defaultEnglis" class="dropdown-item inside" (click)="useLanguage('en')">{{'ingles' |
              translate}}</a>
            <a *ngIf="defaultPortuguese" class="dropdown-item inside" (click)="useLanguage('pt')">{{'portugues' |
              translate}}</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Wrapper -->
<div class="wrapper" *ngIf="isMobile || offset <= 991">
  <!-- Sidebar -->
  <nav class="sidebar light">
    <!-- close sidebar menu -->
    <div class="dismiss" (click)="quitActive()">
      <em class="fa fa-times"></em>
    </div>

    <ul class="list-unstyled menu-elements">

      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{environmentProd}}{{'linkanunciocompra' | translate}}" target="_parent"
          (click)="quitActive()">
          <span>
            {{'purchasingannouncements' | translate}}
          </span>
        </a>
      </li>
      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{environmentProd}}{{'linkdireccionempresas' | translate}}" target="_parent"
          (click)="quitActive()">
          <span>
            {{'connectedcompanies' | translate}}
          </span>
        </a>
      </li>
      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{'linkcapacitacion' | translate}}" target="_parent" (click)="quitActive()">
          <span>
            {{'training' | translate}}
          </span>
        </a>
      </li>
      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{environmentProd}}{{'linkcomunidadesnegocio' | translate}}" target="_parent"
          (click)="quitActive()">
          <span>
            {{'businesscommunities' | translate}}
          </span>
        </a>
      </li>
      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{environmentProd}}{{'linkeventosempresa' | translate}}" target="_parent"
          (click)="quitActive()">
          <span>
            {{'events' | translate}}
          </span>
        </a>
      </li>
      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{environmentProd}}{{'linkserviciofinanciero' | translate}}" target="_parent"
          (click)="quitActive()">
          <span>
            {{'financialservices' | translate}}
          </span>
        </a>
      </li>
      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{environmentProd}}{{'linkhubpodcasts' | translate}}" target="_parent"
          (click)="quitActive()">
          <span>
            {{'elhubpodcasts' | translate}}
          </span>
        </a>
      </li>
      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{environmentProd}}{{'linkmujeresconectamericas' | translate}}" target="_parent"
          (click)="quitActive()">
          <span>
            {{'connectamericaswomen' | translate}}
          </span>
        </a>
      </li>
      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{environmentProd}}{{'linkcasoexito' | translate}}" target="_parent"
          (click)="quitActive()">
          <span>
            {{'successstories' | translate}}
          </span>
        </a>
      </li>
      <li>
        <button class="btn p-0 w-100" type="button" (click)="logInPost()">
          <a class="scroll-link" target="_blank">
            <span>
              {{'iniciarsesion' | translate}}
            </span>
          </a>
        </button>
      </li>
      <li [routerLinkActive]="['active']">
        <a class="scroll-link" href="{{environmentProd}}{{'linkuneteahora' | translate}}" target="_parent"
          (click)="quitActive()">
          <span>
            {{'signin' | translate}}
          </span>
        </a>
      </li>
      <div class="sect-change-lang ">
        <div class="d-flex justify-content-between h-100 content-lang">
          <button class="btn btn-link" type="button" (click)="useLanguage('en')">{{'ingles2' | translate}}</button>
          <button class="btn btn-link link-lang-border" type="button" (click)="useLanguage('es')">{{'espanol2' |
            translate}}</button>
          <button class="btn btn-link" type="button" (click)="useLanguage('pt')">{{'portugues2' |
            translate}}</button>
        </div>
      </div>
    </ul>
  </nav>
  <!-- End sidebar -->

  <!-- Dark overlay -->
  <div class="overlay" (click)="quitActive()"></div>

  <!-- Content -->
  <div class="content cont-mb">
    <a class="navbar-brand" [href]="environmentProd" routerLinkActive="active">
      <img class="img-fluid" src="../../../../assets/img/logo_home_ca.svg" alt="">
    </a>
    <!-- open sidebar menu -->
    <button class="btn btn-customized btn-menutwo open-menu" role="button" style="z-index: 99997;"
      (click)="toggleButtonTwo()">
      <em class="fas fa-bars"></em>
      <span *ngIf="!isMobile">MENÚ</span>
    </button>
  </div>
  <!-- End content -->

</div>
<!-- End wrapper -->
