import { Component, OnInit } from '@angular/core';
import {  ActivationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-connectamericas',
  templateUrl: './connectamericas.component.html',
  styleUrls: ['./connectamericas.component.scss']
})
export class ConnectamericasComponent implements OnInit {
  hiddenMenuFooter = true;
  constructor(private route: Router ) {

  }

  ngOnInit(): void {
    try {
      if (window.localStorage) {
        window.localStorage.removeItem('guest');
      }
    } catch (error) {}
    this.route.events.subscribe((event: any) => {
      if (typeof event.url !== 'undefined') {
        if (event.url.includes('guest')) {
          const urlString = event.url;
          const ulr = new URL('https://tester' + urlString);
          const guest = ulr.searchParams.get('guest');
          if (guest === 'false') {
            try {
              if (window.localStorage) {
                window.localStorage.setItem('guest', 'false');
              }
            } catch (error) {}
            this.hiddenMenuFooter = false;
          } else {
            try {
              if (window.localStorage) {
                window.localStorage.setItem('guest', 'true');
              }
            } catch (error) {}
            this.hiddenMenuFooter = true;
          }
        } else {
          this.menuChangeIfLocalStorageIsActive();
        }
      } else {
        this.menuChangeIfLocalStorageIsActive();
      }
    });
  }

  private menuChangeIfLocalStorageIsActive(): void {
    if (
      window.localStorage &&
      window.localStorage.getItem('guest') !== 'undefined' &&
      window.localStorage.getItem('guest') !== null &&
      window.localStorage.getItem('guest') !== 'null'
    ) {
      let guest: any = false;
      try {
        guest = window.localStorage.getItem('guest');
      } catch (error) {
      }
      this.hiddenMenuFooter = (guest === 'true');
    } else {
      this.hiddenMenuFooter = true;
    }
  }
}
