<div class="content-home w-100">
  <app-nav-menu-pilar *ngIf="hiddenMenuFooter">
  </app-nav-menu-pilar>
  <div class="banner">
    <div class="img-banner">
      <div class="breadcrumb-int">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="https://connectamericas.com/" target="_parent">
              {{ 'home' | translate}}</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/home']">{{ 'connectamericaswomen' | translate}} </a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ 'digitalizacion' | translate}} </li>
        </ol>
      </div>
      <div class="sec-title">
        <h2 class="wow animated fadeIn">
          <a [routerLink]="['/home']">
            <img class="img-fluid" src="{{ 'imgmujereslogo' | translate}}" alt="">
          </a>
        </h2>
        <p class="wow animated fadeIn">
          {{ 'titulocreciendojuntasamerica' | translate}}
        </p>
      </div>
    </div>
  </div>


  <div class="content-card-home">
    <div class="circle1" [ngClass]="{'position-absolute': activeAbsolute}"></div>
    <div class="circle2" [ngClass]="{'position-absolute': activeAbsolute}"></div>
    <div class="card-program">
      <div class="row" *ngIf="pilar">
        <div class="col-12 col-md-8">
          <div class="row">
            <div class="col-2 pr-0 xsrec">
              <div class="img-sec" *ngIf="pilar.fieldIcono.url">
                <img class="img-fluid" src="{{pilar.fieldIcono.url}}" alt="">
              </div>
            </div>
            <div class="col-10 pl-0">
              <h4>Challenge</h4>
              <h3> {{pilar.title}}</h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4" *ngIf="pilar.fieldEnlaceAplicar !== null">
          <a class="btn btn-aplica pt-8" type="button"
          [href]="pilar.fieldEnlaceAplicar.uri" target="_blank" rel="noopener noreferrer">{{ 'aplicaahora' | translate}} </a>
        </div>
        <div class="col-12 col-md-4" *ngIf="pilar.fieldEnlaceAplicar === null">
          <a class="btn btn-closed pt-8" type="button" [href]="" target="_blank"
            rel="noopener noreferrer">{{ 'cerrada' | translate}}</a>
        </div>
        <div class="htmembebet pt-5" [innerHTML]="pilar.fieldTextoInterno.processed"> </div>
        <!-- <button class="btn btn-link" type="button"><em class="fas fa-download"></em>
          <a href="url">{{ 'descargacondiciones' | translate}}</a>
        </button> -->
      </div>
    </div>
  </div>

  <div class="sect-challenge-white">
    <div class="row col-12">
      <div class="col-12 col-md-6">
        <div class="sect-challenge-text">
          <h4>Challenge</h4>
          <h3 *ngIf="pilar"> {{pilar.title}}</h3>
          <p>
            {{ 'cierreaplicaciones' | translate}}
          </p>
          <div *ngIf="pilar.fieldEnlaceAplicar !== null">
            <a class="btn btn-aplica m-0 mt-4 pt-8" type="button" [href]="pilar.fieldEnlaceAplicar.uri" target="_blank"
              rel="noopener noreferrer">{{ 'aplicaahora' | translate}}</a>
          </div>
          <div *ngIf="pilar.fieldEnlaceAplicar === null">
            <a class="btn btn-closed m-0 mt-4 pt-8" type="button" [href]="" target="_blank"
              rel="noopener noreferrer">{{ 'cerrada' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <img class="img-fluid" src="../../../assets/img/img-internalchallenge.svg" alt="">
      </div>
    </div>
    <p class="text-center pt-5"><strong>{{ 'consultas' | translate}}</strong> {{ 'enviacorreo' | translate}}<a
        href="mailto:creciendojuntas@iadb.org" target="_parent" rel="popup"> creciendojuntas@iadb.org</a> </p>
  </div>


  <app-footer-pilar *ngIf="hiddenMenuFooter"></app-footer-pilar>
</div>
